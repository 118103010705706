<template>
  <b-card>
    <div>
      <b-row>
        <b-col>
          <div
            class="custom-search d-flex align-items-center justify-content-end"
          >
            <div
              class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
            >
              <template
                v-if="
                  $permissionAbility(MILESTONE_BOARD_TASK_CREATE, permissions)
                "
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="flex-shrink-0 mr-1 btn-sm"
                  variant="primary"
                  v-on:click="showModal"
                >
                  Create Task
                </b-button>
                <b-sidebar
                  id="sidebar-right"
                  bg-variant="white"
                  right
                  backdrop
                  shadows
                >
                  <div>
                    <validation-observer ref="taskEdit">
                      <b-form v-on:submit.prevent="taskEditForm" class="mr-1">
                        <h4>Edit Task Information</h4>
                        <b-row class="mb-1">
                          <b-col md="10" lg="10" xs="12">
                            <div id="component-breadcrumbs">
                              <b-breadcrumb class="breadcrumb-chevron">
                                <b-breadcrumb-item active>
                                  {{ milestoneBoardInfo?.title }}
                                </b-breadcrumb-item>

                                <b-breadcrumb-item
                                  v-on:click="onShowTask(breadCrumShortTitle)"
                                >
                                  {{ breadCrumTitle }} -({{
                                    breadCrumShortTitle
                                  }})x
                                </b-breadcrumb-item>
                              </b-breadcrumb>
                            </div>
                          </b-col>
                        </b-row>

                        <!-- Title -->
                        <template>
                          <b-row>
                            <b-col md="8" lg="8" xs="12">
                              <b-form-group label="Title *" label-for="title">
                                <ValidationProvider
                                  name="title"
                                  v-slot="{ errors }"
                                  vid="title"
                                  rules="required"
                                >
                                  <b-form-input
                                    id="title"
                                    type="text"
                                    class="custom-font"
                                    v-model="title"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Enter task title"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </ValidationProvider>
                              </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4" xs="12">
                              <b-form-group label="Type " label-for="type">
                                <ValidationProvider
                                  name="type"
                                  v-slot="{ errors }"
                                  vid="type"
                                >
                                  <b-form-input
                                    id="type"
                                    type="text"
                                    v-model="type"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Enter task type"
                                    class="custom-font"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </ValidationProvider>
                              </b-form-group>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col md="4" lg="4" xs="12">
                              <b-form-group
                                label="Priority "
                                label-for="priority"
                              >
                                <ValidationProvider
                                  name="priority"
                                  v-slot="{ errors }"
                                  vid="priority"
                                >
                                  <v-select
                                    id="priority"
                                    v-model="selectPriorityType"
                                    :options="priorityOptionConstants"
                                    :reduce="(option) => option.value"
                                    placeholder="Select task priority"
                                    label="name"
                                    class="custom-font"
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </ValidationProvider>
                              </b-form-group>
                            </b-col>
                            <b-col md="4" lg="4" xs="12">
                              <b-form-group
                                label="Estimation Point"
                                label-for="point"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="point"
                                  vid="point"
                                  rules="min_value:0|max_value:9999"
                                >
                                  <b-form-input
                                    id="point"
                                    type="text"
                                    v-model="point"
                                    :state="errors.length > 0 ? false : null"
                                    placeholder="Enter Estimation Point"
                                    class="custom-font"
                                  />

                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col md="4" lg="4" xs="12">
                              <b-form-group label="Label" label-for="labels">
                                <ValidationProvider
                                  name="labels"
                                  v-slot="{ errors }"
                                  vid="labels"
                                >
                                  <v-select
                                    :dir="
                                      $store.state.appConfig.isRTL
                                        ? 'rtl'
                                        : 'ltr'
                                    "
                                    id="labels"
                                    class="custom-font"
                                    placeholder="Add Task Label"
                                    v-model="selectLabels"
                                    label="name"
                                    multiple
                                    taggable
                                    push-tags
                                  >
                                  </v-select>
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </ValidationProvider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <template v-if="previousFiles.length > 0">
                            <b-row class="mb-2">
                              <b-col>
                                <div class="scrollable-file-container">
                                  <div
                                    class="uploaded-files mb-2 scrollable-file-content"
                                  >
                                    <div
                                      v-for="(item, index) in previousFiles"
                                      :key="index"
                                      class="file-preview"
                                    >
                                      <b-row class="mr-1">
                                        <b-col md="8" lg="8" xs="12">
                                          <div
                                            v-b-tooltip.hover.bottom="
                                              item.file_name
                                            "
                                          >
                                            <template
                                              v-if="isPreviousFileImage(item)"
                                            >
                                              <img
                                                :src="item?.original_url"
                                                alt="Preview"
                                                width="100"
                                                height="100"
                                              />
                                            </template>
                                            <template v-else>
                                              <svg
                                                width="100px"
                                                height="100px"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="#000000"
                                              >
                                                <g
                                                  id="SVGRepo_bgCarrier"
                                                  stroke-width="0"
                                                ></g>
                                                <g
                                                  id="SVGRepo_tracerCarrier"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></g>
                                                <g id="SVGRepo_iconCarrier">
                                                  <title></title>
                                                  <g id="Complete">
                                                    <g id="F-File">
                                                      <g id="Text">
                                                        <g>
                                                          <path
                                                            d="M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z"
                                                            fill="none"
                                                            id="File"
                                                            stroke="#000000"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="2"
                                                          ></path>
                                                          <line
                                                            fill="none"
                                                            stroke="#000000"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="2"
                                                            x1="7.9"
                                                            x2="16.1"
                                                            y1="17.5"
                                                            y2="17.5"
                                                          ></line>
                                                          <line
                                                            fill="none"
                                                            stroke="#000000"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="2"
                                                            x1="7.9"
                                                            x2="16.1"
                                                            y1="13.5"
                                                            y2="13.5"
                                                          ></line>
                                                          <line
                                                            fill="none"
                                                            stroke="#000000"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            stroke-width="2"
                                                            x1="8"
                                                            x2="13"
                                                            y1="9.5"
                                                            y2="9.5"
                                                          ></line>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </svg>
                                            </template>
                                          </div>
                                        </b-col>
                                        <b-col md="3" lg="3" xs="12">
                                          <b-button
                                            class="flex-shrink-0 btn-sm"
                                            v-ripple.400="
                                              'rgba(255, 255, 255, 0.15)'
                                            "
                                            variant="outline-danger"
                                            @click="removePreviousFile(index)"
                                          >
                                            <feather-icon icon="XIcon" />
                                          </b-button>
                                        </b-col>
                                      </b-row>
                                    </div>
                                  </div>
                                </div>
                              </b-col>
                            </b-row>
                          </template>

                          <b-row>
                            <b-col>
                              <div class="file-input">
                                <label for="file-upload" class="attach-icon">
                                  Attach File
                                  <feather-icon
                                    icon="PaperclipIcon"
                                    class="mr-50"
                                    style="color: #7367f0"
                                  />
                                </label>
                                <input
                                  hidden
                                  id="file-upload"
                                  type="file"
                                  multiple
                                  @change="handleFileUpload"
                                />
                              </div>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <div class="scrollable-file-container">
                                <div
                                  class="uploaded-files mb-2 scrollable-file-content"
                                >
                                  <div
                                    v-for="(file, index) in uploadedFiles"
                                    :key="index"
                                    class="file-preview"
                                  >
                                    <b-row class="mr-1">
                                      <b-col md="8" lg="8" xs="12">
                                        <div
                                          v-b-tooltip.hover.bottom="file.name"
                                        >
                                          <template v-if="isImage(file)">
                                            <img
                                              :src="file.preview"
                                              alt="Preview"
                                              width="100"
                                              height="100"
                                            />
                                          </template>
                                          <template v-else>
                                            <svg
                                              width="100px"
                                              height="100px"
                                              viewBox="0 0 24 24"
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="#000000"
                                            >
                                              <g
                                                id="SVGRepo_bgCarrier"
                                                stroke-width="0"
                                              ></g>
                                              <g
                                                id="SVGRepo_tracerCarrier"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></g>
                                              <g id="SVGRepo_iconCarrier">
                                                <title></title>
                                                <g id="Complete">
                                                  <g id="F-File">
                                                    <g id="Text">
                                                      <g>
                                                        <path
                                                          d="M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z"
                                                          fill="none"
                                                          id="File"
                                                          stroke="#000000"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                          stroke-width="2"
                                                        ></path>
                                                        <line
                                                          fill="none"
                                                          stroke="#000000"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                          stroke-width="2"
                                                          x1="7.9"
                                                          x2="16.1"
                                                          y1="17.5"
                                                          y2="17.5"
                                                        ></line>
                                                        <line
                                                          fill="none"
                                                          stroke="#000000"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                          stroke-width="2"
                                                          x1="7.9"
                                                          x2="16.1"
                                                          y1="13.5"
                                                          y2="13.5"
                                                        ></line>
                                                        <line
                                                          fill="none"
                                                          stroke="#000000"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                          stroke-width="2"
                                                          x1="8"
                                                          x2="13"
                                                          y1="9.5"
                                                          y2="9.5"
                                                        ></line>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </svg>
                                          </template>
                                        </div>
                                      </b-col>
                                      <b-col md="3" lg="3" xs="12">
                                        <b-button
                                          class="flex-shrink-0 btn-sm"
                                          v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                          "
                                          variant="outline-danger"
                                          @click="removeFile(index)"
                                        >
                                          <feather-icon icon="XIcon" />
                                        </b-button>
                                      </b-col>
                                    </b-row>
                                  </div>
                                </div>
                              </div>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col>
                              <b-form-group
                                label="Description"
                                label-for="description"
                              >
                                <ValidationProvider
                                  #default="{ errors }"
                                  name="description"
                                  vid="description"
                                >
                                  <quill-editor
                                    v-model="description"
                                    :options="editorOption"
                                    :style="{
                                      height: '13rem !important',
                                      paddingBottom: '5rem !important',
                                    }"
                                  >
                                    <div id="toolbar" slot="toolbar">
                                      <span class="ql-formats">
                                        <button class="ql-bold">Bold</button>
                                        <button class="ql-italic">
                                          Italic
                                        </button>
                                        <button class="ql-underline">
                                          Underline
                                        </button>
                                        <button class="ql-strike">
                                          Strike
                                        </button>
                                        <button class="ql-blockquote"></button>
                                        <button
                                          class="ql-list"
                                          value="ordered"
                                        ></button>
                                        <button
                                          class="ql-list"
                                          value="bullet"
                                        ></button>
                                        <button
                                          class="ql-script"
                                          value="sub"
                                        ></button>
                                        <button
                                          class="ql-script"
                                          value="super"
                                        ></button>
                                        <button
                                          class="ql-indent"
                                          value="-1"
                                        ></button>
                                        <button
                                          class="ql-indent"
                                          value="+1"
                                        ></button>
                                        <button
                                          class="ql-direction"
                                          value="rtl"
                                        ></button>
                                        <button
                                          class="ql-align"
                                          value=""
                                        ></button>
                                        <button
                                          class="ql-align"
                                          value="center"
                                        ></button>
                                        <button
                                          class="ql-align"
                                          value="right"
                                        ></button>
                                        <button
                                          class="ql-align"
                                          value="justify"
                                        ></button>
                                        <select class="ql-color"></select>
                                        <select class="ql-background"></select>
                                        <select class="ql-size">
                                          <option value="small" />

                                          <option selected />
                                          <option value="large" />
                                          <option value="huge" />
                                        </select>

                                        <select class="ql-font"></select>
                                        <select class="ql-header">
                                          <option value="1">Heading 1</option>
                                          <option value="2">Heading 2</option>
                                          <option value="3">Heading 3</option>
                                          <option value="4">Heading 4</option>
                                          <option value="5">Heading 5</option>
                                          <option value="6">Heading 6</option>
                                          <option selected>Normal</option>
                                        </select>

                                        <button class="ql-link"></button>
                                      </span>
                                    </div>
                                  </quill-editor>

                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </ValidationProvider>
                              </b-form-group>
                            </b-col>
                          </b-row>

                          <b-row>

                            <b-col md="4" lg="4" xs="12">
                              <b-form-group
                                  label="Deadline"
                                  label-for="deadline"
                              >
                                <ValidationProvider
                                    name="deadline"
                                    v-slot="{ errors }"
                                    vid="deadline"
                                >
                                  <b-form-datepicker
                                      id="deadline"
                                      v-model="deadline"
                                      class="form-control custom-font"
                                      :state="errors.length > 0 ? false : null"
                                      locale="en-US"
                                      today-button
                                      close-button
                                      reset-button
                                      placeholder="Deadline"
                                  />
                                  <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                </ValidationProvider>
                              </b-form-group>
                            </b-col>
                            
                            <b-col md="4" lg="4" xs="12">
                              <b-form-group
                                label="Actual Start Date"
                                label-for="actual_start_date"
                              >
                                <ValidationProvider
                                  name="actual_start_date"
                                  v-slot="{ errors }"
                                  vid="actual_start_date"
                                >
                                  <b-form-datepicker
                                    id="actual_start_date"
                                    v-model="actualStartDate"
                                    class="form-control custom-font"
                                    :state="errors.length > 0 ? false : null"
                                    locale="en-US"
                                    today-button
                                    close-button
                                    reset-button
                                    placeholder="Actual Start Date "
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </ValidationProvider>
                              </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4" xs="12">
                              <b-form-group
                                label="Actual End Date"
                                label-for="actual_end_date"
                              >
                                <ValidationProvider
                                  name="actual_end_date"
                                  v-slot="{ errors }"
                                  vid="actual_end_date"
                                >
                                  <b-form-datepicker
                                    id="actual_end_date"
                                    v-model="actualEndDate"
                                    class="form-control custom-font"
                                    :state="errors.length > 0 ? false : null"
                                    locale="en-US"
                                    today-button
                                    close-button
                                    reset-button
                                    placeholder="Actual End Date "
                                  />
                                  <small class="text-danger">{{
                                    errors[0]
                                  }}</small>
                                </ValidationProvider>
                              </b-form-group>
                            </b-col>
                          </b-row>

                          <b-row class="mt-1 mb-1">
                            <template
                              v-if="
                                $permissionAbility(
                                  MILESTONE_BOARD_SUB_TASK_CREATE,
                                  permissions
                                )
                              "
                            >
                              <b-col>
                                <b-button
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="outline-primary"
                                  class="btn btn-sm"
                                  v-on:click="showSubTaskForm"
                                >
                                  <feather-icon
                                    icon="BriefcaseIcon"
                                    class="mr-50"
                                  />
                                  Add Sub Task
                                </b-button>
                              </b-col>
                            </template>
                          </b-row>
                          <template v-if="isSubTaskFormLoaded">
                            <b-row class="mt-1 mb-1">
                              <b-col md="12" lg="12" xs="12">
                                <validation-observer ref="subTaskAdd">
                                  <b-form v-on:submit.prevent="subTaskAddForm">
                                    <b-row
                                      ><b-col md="8" lg="8" xs="12">
                                        <ValidationProvider
                                          name="sub_task_title"
                                          v-slot="{ errors }"
                                          vid="title"
                                        >
                                          <b-form-input
                                            id="title"
                                            type="text"
                                            class="custom-font"
                                            v-model="subTaskTitle"
                                            :state="
                                              errors.length > 0 ? false : null
                                            "
                                            placeholder="Enter Sub Task Title"
                                          />
                                          <small class="text-danger">{{
                                            errors[0]
                                          }}</small>
                                        </ValidationProvider>
                                      </b-col>
                                      <b-col md="4" lg="4" xs="12">
                                        <template v-if="isSubTaskLoading">
                                          <b-button
                                            class="float-right"
                                            variant="primary"
                                            disabled
                                          >
                                            <b-spinner small />
                                            Loading...
                                          </b-button> </template
                                        ><template v-else>
                                          <b-button
                                            class="float-right btn-sm"
                                            v-ripple.400="
                                              'rgba(255, 255, 255, 0.15)'
                                            "
                                            variant="primary"
                                            v-on:click="closeSubTaskForm"
                                          >
                                            Cancel
                                          </b-button>
                                          <b-button
                                            type="submit"
                                            class="float-right mr-1 btn-sm"
                                            v-ripple.400="
                                              'rgba(255, 255, 255, 0.15)'
                                            "
                                            variant="primary"
                                          >
                                            Add
                                          </b-button>
                                        </template>
                                      </b-col>
                                    </b-row>
                                  </b-form>
                                </validation-observer>
                              </b-col>
                            </b-row>
                          </template>
                          <b-row class="mt-1">
                            <b-col md="12" lg="12" xs="12">
                              <b-list-group>
                                <b-list-group-item
                                  class="d-flex justify-content-between align-items-center"
                                  v-for="subTask in subTaskList"
                                  :key="subTask?.id"
                                >
                                  <b-link
                                    v-on:click="onShowSubTask(subTask?.id)"
                                    >{{ subTask?.title }}</b-link
                                  >

                                  <div
                                    class="custom-search d-flex align-items-center justify-content-end"
                                  >
                                    <b-badge
                                      variant="light-primary"
                                      class="mr-1"
                                      >{{
                                        subTask?.phase?.data?.title
                                      }}</b-badge
                                    >

                                    <b-button
                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                      variant="primary"
                                      class="mr-1 btn-sm"
                                      v-on:click="onShowSubTask(subTask?.id)"
                                    >
                                      <feather-icon icon="EditIcon" />
                                    </b-button>
                                    <b-button
                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                      variant="danger"
                                      class="btn-sm"
                                      v-on:click="onDeleteSubTask(subTask?.id)"
                                    >
                                      <feather-icon icon="TrashIcon" />
                                    </b-button>
                                  </div>
                                </b-list-group-item>
                              </b-list-group>
                            </b-col>
                          </b-row>
                        </template>

                        <!-- loading button -->
                        <template
                          v-if="
                            $permissionAbility(
                              MILESTONE_BOARD_TASK_EDIT,
                              permissions
                            )
                          "
                        >
                          <template v-if="isLoading">
                            <b-button
                              class="float-right"
                              variant="primary"
                              disabled
                            >
                              <b-spinner small />
                              Loading...
                            </b-button>
                          </template>

                          <!-- submit button -->
                          <template v-else>
                            <b-button
                              type="submit"
                              class="float-right mt-2 mb-2"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                            >
                              Submit
                            </b-button>
                          </template>
                        </template>
                      </b-form>
                    </validation-observer>
                  </div>
                </b-sidebar>
              </template>
            </div>
          </div>
        </b-col>
      </b-row>

      <div v-if="isBacklogTaskListLoading" class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          class="mr-1"
          variant="primary"
        />
      </div>
      <div v-else>
        <template v-if="backlogTaskList.length > 0">
          <b-list-group>
            <div v-for="(item, index) in backlogTaskList" :key="index">
              <b-list-group-item
                class="d-flex justify-content-between align-items-center"
              >
                <b-link style="color: black"
                  v-on:click="onShowTaskSidebar(item)"
                  v-b-toggle.sidebar-right
                  >{{ item?.title }}
                </b-link>

                <div
                  class="custom-search d-flex align-items-center justify-content-end"
                >
                  <div class="mr-1">
                    <div>
                      <span
                        v-for="(member, index) in item?.team?.data"
                        :key="index"
                      >
                        <template v-if="index <= 2">
                          <b-avatar
                            :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar"
                            size="26"
                            style="margin: 1px"
                        /></template>
                      </span>
                      <span v-if="item?.team_count > 3">
                        + {{ item?.team_count - 3 }} more
                      </span>
                      <b-button
                        variant="outline-primary"
                        class="btn-icon rounded-circle"
                        style="margin-left: 5px"
                        v-on:click="showAddMemberModal(item)"
                        :disabled="
                          $permissionAbility(
                            MILESTONE_BOARD_TASK_DELETE,
                            permissions
                          )
                            ? false
                            : true
                        "
                      >
                        <feather-icon icon="UserPlusIcon" />
                      </b-button>
                    </div>
                  </div>

                  <div class="mr-1">
                    <b-dropdown
                      no-caret
                      variant="outline-primary"
                      :text="item?.phase?.data?.title"
                      size="sm"
                    >
                      <b-dropdown-item
                        v-for="phase in phaseIdOptions"
                        :key="phase?.id"
                        v-on:click="updatePhase(item, phase?.id)"
                      >
                        {{ phase?.title }}
                      </b-dropdown-item>
                      <b-dropdown-divider />
                      <b-dropdown-item v-on:click="onShowPhase">
                        <feather-icon icon="PlusCircleIcon" /> Add More
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <template
                    v-if="
                      $permissionAbility(
                        MILESTONE_BOARD_TASK_DELETE,
                        permissions
                      ) ||
                      $permissionAbility(MILESTONE_BOARD_TASK_EDIT, permissions)
                    "
                  >

                    <template v-if="
                            $permissionAbility(
                              MILESTONE_BOARD_TASK_EDIT,
                              permissions
                            )
                          "  v-on:click="onShowTaskSidebar(item)">
                      <feather-icon
                          v-b-tooltip.hover
                          v-b-toggle.sidebar-right
                          icon="Edit2Icon"
                          class="mr-50 custom-icon cursor-pointer"
                          title="Edit"
                          size="16"
                      />
                    </template>

                    <feather-icon
                        v-if="
                            $permissionAbility(
                              MILESTONE_BOARD_TASK_DELETE,
                              permissions
                            )
                          "
                        v-on:click="onDelete(item?.id)"
                        v-b-tooltip.hover
                        icon="TrashIcon"
                        class="mr-50 custom-icon cursor-pointer"
                        title="Delete"
                        size="16"
                    />

                  </template>
                </div>
              </b-list-group-item>
            </div>
          </b-list-group>
        </template>
        <template v-else>
          <b-list-group>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
            >
              <h5>No Task Found In Back Log</h5>
            </b-list-group-item>
          </b-list-group>
        </template>
      </div>
    </div>

    <b-modal
      id="modal-task-board-phase-task"
      centered
      title="Create Task"
      hide-footer
      @hidden="hiddenModal"
      size="lg"
      no-close-on-backdrop
    >
      <validation-observer ref="backlogTask">
        <b-form v-on:submit.prevent="backlogTaskForm">
          <!-- Title -->

          <b-row>
            <b-col md="12" lg="12" xs="12">
              <b-form-group label="Title *" label-for="title">
                <ValidationProvider
                  name="title"
                  v-slot="{ errors }"
                  vid="title"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    type="text"
                    class="custom-font"
                    v-model="title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter task title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- loading button -->
          <template v-if="isLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right mt-2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-add-member-form"
      centered
      title="Add Member"
      hide-footer
      @hidden="hiddenAddMemberModal"
      no-close-on-backdrop
    >
      <validation-observer ref="addMemberValidation">
        <b-form v-on:submit.prevent="addMemberValidationForm">
          <!-- select member-->
          <b-form-group label="Member" label-for="user_id">
            <ValidationProvider
              name="user_id"
              v-slot="{ errors }"
              vid="user_id"
            >
              <v-select
                id="user_id"
                placeholder="Search by employee name or email"
                v-model="selectMemberIds"
                :filterable="false"
                :options="memberIdOptions"
                :reduce="(country) => country.id"
                label="name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
              >
                <template #option="data">
                  <UserSelect :user="data" />
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Add
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-task-board-phase"
      centered
      title="Change Phase"
      hide-footer
      @hidden="hiddenPhaseModal"
      no-close-on-backdrop
    >
      <validation-observer ref="changePhaseValidation">
        <b-form v-on:submit.prevent="changePhaseValidationForm">
          <!-- select phase-->
          <b-form-group label="Phase" label-for="phase_id">
            <ValidationProvider
              name="phase_id"
              v-slot="{ errors }"
              vid="phase_id"
            >
              <v-select
                id="phase_id"
                placeholder="Search by phase name"
                v-model="selectPhaseId"
                :options="phaseIdOptions"
                :reduce="(item) => item.id"
                label="name"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Save
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

        <script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormTextarea,
  BFormDatepicker,
  BLink,
  BSidebar,
  VBToggle,
  VBTooltip,
  BListGroup,
  BListGroupItem,
  BBreadcrumb,
  BBreadcrumbItem,
  BDropdownDivider,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max, size, ext, max_value, min_value } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  MILESTONE_BOARD_SUB_TASK_CREATE,
  MILESTONE_BOARD_TASK_CREATE,
  MILESTONE_BOARD_TASK_EDIT,
  MILESTONE_BOARD_TASK_DELETE,
} from "@/helpers/permissionsConstant";
import LvColorPicker from "lightvue/color-picker";
import { priorityOptionConstants } from "@/helpers/constant/priorityOptionConstant";
import UserSelect from '@/layouts/components/UserSelect.vue'

export default {
  name: "BacklogView",
  components: {
    UserSelect,
    BRow,
    BCol,
    LvColorPicker,
    BForm,
    BButton,
    BCard,
    BBadge,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormTextarea,
    BFormDatepicker,
    BLink,
    quillEditor,
    BSidebar,
    BListGroup,
    BListGroupItem,
    BBreadcrumb,
    BBreadcrumbItem,
    BDropdownDivider,
  },
  directives: {
    "b-toggle": VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },

      MILESTONE_BOARD_SUB_TASK_CREATE,
      MILESTONE_BOARD_TASK_CREATE,
      MILESTONE_BOARD_TASK_EDIT,
      MILESTONE_BOARD_TASK_DELETE,

      modelType: "",

      boardInfo: "",
      //phase
      selectPhaseId: "",
      phaseIdOptions: [],

      //sub task
      isSubTaskFormLoaded: false,
      subTaskTitle: "",
      //task
      breadCrumTitle: "",
      breadCrumShortTitle: "",
      milestoneBoardInfo: "",
      isBacklogTaskListLoading: false,
      selectedTaskInfo: {},
      backlogTaskList: [],
      taskId: "",
      title: "",
      description: "",
      summary: "",
      type: "",
      selectPriorityType: "",
      priorityOptionConstants,
      point: "",
      startDate: "",
      actualStartDate: "",
      deadline: "",
      actualEndDate: "",
      selectLabels: [],
      labelIdOptions: [],
      searchLabelIdOptions: [],
      selectMemberIds: [],
      memberIdOptions: [],
      previousFiles: [],
      uploadedFiles: [],
      taskFiles: [],
      subTaskList: [],
      isSearchingMember: false,
      isSubTaskLoading: false,
      pageLength: 10,
      columns: [
        {
          label: "Title",
          field: "format_title",
          sortable: false,
        },

        {
          label: "Members",
          field: "format_members",
          sortable: false,
        },
        {
          label: "Phase",
          field: "format_phase",
          sortable: false,
        },
        {
          label: "Created On",
          field: "created_at",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      delayTimer: null,
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          // { field: "id", type: "desc" },
          // { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 50,
      },
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      this.isBacklogTaskListLoading = true;
      await this.loadItems();

      const milestoneBoardInfo = await this.getMilestoneTaskBoardPhases();

      this.milestoneBoardInfo = milestoneBoardInfo?.data?.data;
      const nonBackLogPhases = (
        milestoneBoardInfo?.data?.data?.phases?.data || []
      ).filter((item) => item?.is_backlog == false);
      this.phaseIdOptions = (nonBackLogPhases || []).map((item) => {
        return {
          title: item?.title,
          id: item?.id,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    } finally {
      this.isBacklogTaskListLoading = false;
    }
  },
  methods: {
    /*** File Upload Start ***/
    handleFileUpload(event) {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (!this.checkDuplicateFile(file)) {
          this.uploadedFiles.push({
            name: file.name,
            preview: URL.createObjectURL(file),
            type: file.type,
          });

          this.taskFiles.push(file);
        }
      }
    },
    checkDuplicateFile(file) {
      return this.uploadedFiles.some(
        (uploadedFile) => uploadedFile.name === file.name
      );
    },
    isImage(file) {
      return file.type.startsWith("image/");
    },
    removeFile(index) {
      this.uploadedFiles.splice(index, 1);
      this.taskFiles.splice(index, 1);
    },

    //previous file
    isPreviousFileImage(file) {
      return file.mime_type.startsWith("image/");
    },
    removePreviousFile(index) {
      this.previousFiles.splice(index, 1);
    },
    /*** File Upload End***/
    onShowMilestones() {
      this.$router.push({
        name: "admin-milestones",
      });
    },
    onShowDepartmentDetails() {
      const id = this.boardInfo?.department?.data?.id;
      this.$router.push({
        name: "admin-department-details",
        params: { id },
      });
    },
    onShowPage() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "admin-task-board-details",
        params: { id },
      });
    },
    formatMilestoneType(value) {
      if (value) {
        return "Extra";
      }
      return "Primary";
    },
    formatType(value) {
      if (value) {
        return value;
      }
      return "N/A";
    },
    goToTrash() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "admin-task-board-tasks-trash",
        params: { id },
      });
    },
    showModal() {
      this.$bvModal.show("modal-task-board-phase-task");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-task-board-phase-task");
      this.resetModal();
    },

    resetModal() {
      this.modelType = "";
      this.taskId = "";
      this.title = "";
      this.description = "";
      this.summary = "";
      this.type = "";
      this.selectPriorityType = "";
      this.priorityOptionConstants;
      this.point = "";
      this.startDate = "";
      this.actualStartDate = "";
      this.deadline = "";
      this.actualEndDate = "";
      this.selectLabels = [];
      this.previousFiles = [];
      this.uploadedFiles = [];
      this.taskFiles = [];
    },

    onDeleteSubTask(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Move This To Trash?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(
              `api/milestone-task-boards/phases/tasks/subtasks/${id}`
            );
            this.loadItems();
            this.resetSidebar(this.taskId);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Sub Task Moved To Trash",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    onShowSubTask(id) {
      this.$router.push({
        name: "admin-task-board-task-subtask-details",
        params: { id },
      });
    },

    async resetSidebar(id) {
      this.uploadedFiles = [];
      this.taskFiles = [];

      const taskInfo = await this.$api.get(
        `api/milestone-task-boards/phases/tasks/${id}?include=labels,subTasks.phase`
      );

      this.onShowTaskSidebar(taskInfo?.data?.data);
    },
    showPhaseModal(value) {
      this.taskId = value?.id;
      this.$bvModal.show("modal-task-board-phase");
    },
    hiddenPhaseModal() {
      this.$bvModal.hide("modal-task-board-phase");
      this.resetPhaseModal();
    },
    resetPhaseModal() {
      this.taskId = "";
      this.selectPhaseId = "";
    },
    onShowTaskSidebar(value) {
      this.isSubTaskFormLoaded = false;
      this.subTaskTitle = "";

      this.taskId = value?.id;
      this.breadCrumTitle = value?.title;
      this.breadCrumShortTitle = value?.short_title;
      this.title = value?.title;
      this.subTaskList = value?.subTasks?.data;
      this.description = value?.description;
      this.summary = value?.summary;
      this.type = value?.type;
      this.selectPriorityType = value?.priority;
      this.point = value?.point;
      this.actualStartDate = value?.actual_start_date;
      this.deadline = value?.deadline;
      this.actualEndDate = value?.actual_end_date;
      this.previousFiles = (value?.files || []).map((item) => item);
      this.selectLabels = (value?.labels?.data || []).map((item) => item?.name);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.params = (params || []).map((item) => {
        let field = item.field;

        return {
          field: field,
          type: item.type,
        };
      });

      this.updateParams({
        sort: this.params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onShowPhase() {
      const id = this.$route.params.id;
      this.$router.push({
        name: "admin-task-board-phases",
        params: { id },
      });
    },
    async updatePhase(task, phaseId) {
      try {
        const formData = new FormData();
        formData.append("_method", "PUT");

        if (phaseId) {
          formData.append("phase_id", phaseId);
        }
        await this.$api.post(
          `api/milestone-task-boards/phases/tasks/${task?.id}/update-phase`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.loadItems();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Task Phase Updated Successfully",
          },
        });
      } catch (error) {
        if (error?.response?.data?.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: error?.response?.data?.message,
            },
          });
        }
      }
    },
    async getMilestoneTaskBoardPhases() {
      const milestoneTaskBoardId = this.$route.params.id;

      return await this.$api.get(
        `/api/milestone-task-boards/${milestoneTaskBoardId}?include=phases`
      );
    },
    async getTaskLabelsWithSearch(params) {
      return await this.$api.get("api/lables/lable-with-search", {
        params: {
          q: params.q,
        },
      });
    },
    async showAddMemberModal(value) {
      this.taskId = value?.id;

      const milestoneBoardInfo = await this.getMilestoneBoardTeam();

      const teamMembers = milestoneBoardInfo?.data?.data?.team?.data;

      this.selectMemberIds = (value?.team?.data).map((item) => item?.id);
      this.memberIdOptions = teamMembers.map((item) => {
        return {
          avatar: item?.avatar,
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id,
        }
      });
      this.$bvModal.show("modal-add-member-form");
    },

    hiddenAddMemberModal() {
      this.$bvModal.hide("modal-add-member-form");
      this.resetAddMemberModal();
    },
    resetAddMemberModal() {
      this.taskId = "";
      this.selectMemberIds = [];
    },

    async onDelete(id) {
      // const milestoneTaskBoardId = this.$route.params.id;
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Move This To Trash?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(
              `api/milestone-task-boards/phases/tasks/${id}`
            );
            this.loadItems();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Task Moved To Trash",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    showSubTaskForm() {
      this.isSubTaskFormLoaded = true;
    },
    closeSubTaskForm() {
      this.subTaskTitle = "";
      this.isSubTaskFormLoaded = false;
    },

    onShowTask(task) {
      const id = this.taskId;
      this.$router.push({
        name: "admin-task-board-task-details",
        params: { id, task },
      });
    },

    async getMilestoneBoardTeam() {
      const milestoneTaskBoardId = this.$route.params.id;
      return await this.$api.get(
        `/api/milestone-task-boards/${milestoneTaskBoardId}?include=team`
      );
    },

    async getBacklogTasks() {
      const milestoneTaskBoardId = this.$route.params.id;
      return await this.$api.get(
        `/api/milestone-task-boards/${milestoneTaskBoardId}/backlog/tasks?include=team,phase,labels,subTasks.phase`
      );
    },
    async getBoardInfo() {
      const milestoneTaskBoardId = this.$route.params.id;
      return await this.$api.get(
        `/api/milestone-task-boards/${milestoneTaskBoardId}?include=department,milestone`
      );
    },

    async loadItems() {
      try {
        const [backlogTaskList, boardInfo] = await Promise.all([
          this.getBacklogTasks({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
          this.getBoardInfo(),
        ]);

        this.boardInfo = boardInfo?.data?.data;

        const data = backlogTaskList?.data?.data;

        this.backlogTaskList = data;

        this.rows = data;

        const meta = backlogTaskList?.data?.meta;
        this.totalRecords = meta?.pagination?.total;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error ",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    backlogTaskForm: async function () {
      this.$refs.backlogTask.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;
            const milestoneTaskBoardId = this.$route.params.id;
            const formData = new FormData();

            if (this.title) {
              formData.append("title", this.title);
            }

            await this.$api.post(
              `/api/milestone-task-boards/${milestoneTaskBoardId}/phases/tasks`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            this.isLoading = false;
            this.loadItems();
            this.hiddenModal();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Task Created Successfully",
              },
            });
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.backlogTask.setErrors(error?.response?.data?.errors);
            }
          }
        }
      });
    },

    taskEditForm: async function () {
      this.$refs.taskEdit.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;
            const formData = new FormData();

            formData.append("_method", "PUT");
            if (this.title) {
              formData.append("title", this.title);
            }

            if (this.description) {
              formData.append("description", this.description);
            } else {
              formData.append("description", "");
            }

            if (this.summary) {
              formData.append("summary", this.summary);
            } else {
              formData.append("summary", "");
            }

            if (this.type) {
              formData.append("type", this.type);
            } else {
              formData.append("type", "");
            }

            if (this.selectPriorityType) {
              formData.append("priority", this.selectPriorityType);
            } else {
              formData.append("priority", "");
            }

            if (this.point) {
              formData.append("point", this.point);
            } else {
              formData.append("point", "");
            }

            if (this.actualStartDate) {
              formData.append("actual_start_date", this.actualStartDate);
            } else {
              formData.append("actual_start_date", "");
            }

            if (this.deadline) {
              formData.append("deadline", this.deadline);
            } else {
              formData.append("deadline", "");
            }

            if (this.actualEndDate) {
              formData.append("actual_end_date", this.actualEndDate);
            } else {
              formData.append("actual_end_date", "");
            }

            this.selectLabels.forEach(function (element) {
              formData.append("labels[]", element);
            });

            this.previousFiles.forEach(function (element) {
              formData.append("previous_files[]", element.id);
            });

            this.taskFiles.forEach(function (element) {
              formData.append("files[]", element);
            });

            await this.$api.post(
              `/api/milestone-task-boards/phases/tasks/${this.taskId}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            this.isLoading = false;
            this.loadItems();
            this.resetSidebar(this.taskId);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Task Information Updated Successfully",
              },
            });
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.backlogTask.setErrors(error?.response?.data?.errors);
            }
          }
        }
      });
    },
    addMemberValidationForm: async function () {
      this.$refs.addMemberValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isLoading = true;

            await this.$api.post(
              `api/milestone-task-boards/tasks/${this.taskId}/assign-team`,
              {
                user_ids: this.selectMemberIds,
              }
            );
            this.isLoading = false;
            this.loadItems();
            this.hiddenAddMemberModal();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Member Added Successfully",
              },
            });
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }
            if (error?.response?.data?.errors) {
              this.$refs.milestoneTaskBoardValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },

    subTaskAddForm: async function () {
      this.$refs.subTaskAdd.validate().then(async (success) => {
        if (success) {
          try {
            this.isSubTaskLoading = true;

            await this.$api.post(
              `/api/milestone-task-boards/phases/tasks/${this.taskId}/subtasks`,
              {
                title: this.subTaskTitle,
              }
            );

            this.isSubTaskLoading = false;
            this.loadItems();
            this.resetSidebar(this.taskId);
            this.closeSubTaskForm();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Sub Task Added Successfully",
              },
            });
          } catch (error) {
            this.isSubTaskLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.subTaskAdd.setErrors(error?.response?.data?.errors);
            }
          }
        }
      });
    },

    changePhaseValidationForm: async function () {
      this.$refs.changePhaseValidation.validate().then(async (success) => {
        if (success) {
          try {
            const milestoneTaskBoardId = this.$route.params.id;
            this.isLoading = true;

            await this.$api.get(
              `api/milestone-task-boards/phases/${this.selectPhaseId}/tasks/${this.taskId}`
            );
            this.isLoading = false;
            this.loadItems();
            this.hiddenPhaseModal();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Phase Changed Successfully",
              },
            });
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }
            if (error?.response?.data?.errors) {
              this.$refs.milestoneTaskBoardValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>
        <style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/quill.scss";
.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.table-cell-margin {
  margin: 8px 0px;
}
.custom-font {
  font-size: 13px !important;
}

.file-input {
  display: inline-block;
  position: relative;
}

.attach-icon {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.uploaded-files {
  margin-top: 20px;
}

.file-preview {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 5px;
}

.table-cell-margin {
  margin: 8px 0px;
}
.custom-font {
  font-size: 13px !important;
}
.task-form {
  width: 50%;
}
#sidebar-right {
  width: 50%;
  padding: 15px;
}
// .scrollable-file-container {
//   overflow-x: auto;
//   white-space: nowrap;
// }

// .scrollable-file-content {
//   display: inline-block;
// }
</style>






